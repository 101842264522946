import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessesFetchingAction } from '../../../redux/actions/businessesActions';
import { columns } from './components/BusinessesDataColumns';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import ReactTooltip from 'react-tooltip';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteBusinesses, getBusinessLogo } from '../../../utils/api';
import ConfirmModal from '../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import { base64Decode } from '../../../utils/helpers';
import BusinessChangeLogoModal from './components/BusinessChangeLogoModal/BusinessChangeLogoModal';
import { parse, stringify } from 'query-string';


const TableLogo = ({id}) => {
  const [logoSrc, setLogoSrc] = useState("");
  const [changeLogoModal, setChangeLogoModal] = useState(false);
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getBusinessLogo(id, authUser.accessToken).then(res => {
      setLogoSrc(res.data.data.logo);
    })
  }, [id])

  return <div className="business-change-logo-wrapper">
    <img src={logoSrc ? `${base64Decode(logoSrc)}` : `${process.env.PUBLIC_URL}/img/user-avatar.svg`} alt='Avatar' />
    <button className="btn-business-change-logo" onClick={() => setChangeLogoModal(!changeLogoModal)}>Change logo</button>

    <BusinessChangeLogoModal modal={changeLogoModal} toggle={() => setChangeLogoModal(!changeLogoModal)} id={id}/>
  </div>
}

const Businesses = () => {
  const { t } = useTranslation('common');
  const { businesses, errors, totalRecords } = useSelector(state => state.businesses);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query] = useState({start: 0, limit: 10});
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [failedDeleteModal, setFailedDeleteModal] = useState(false);
  const [messageDeleteError, setMessageDeleteError] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getBusinessesFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, dispatch, refreshCounter, query, location.pathname, navigate]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleDeleteBusinesses = () => {
    deleteBusinesses(deleteId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setSuccessDeleteModal(!successDeleteModal);
      } else {
        setMessageDeleteError(res.data.errors[0].message);
        setFailedDeleteModal(!failedDeleteModal);
      }
    })
  }


  return (
    <Container>
      <Row>
        <Col className="col-12 d-flex justify-content-end">
          <button className="btn btn-success" onClick={() => {
            navigate("/settings/businesses/add", {
              state: {
                prevQuery: [{
                  query: location.search,
                  path: location.pathname,
                }]
              }
            });
          }}>Add a new business</button>
        </Col>
        <Col className="col-12">
          <DataReactTable
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            classList={"businesses-table"}
            sortTable={true}
            search={false}
            emptyStateMessage={t('empty_states.businesses')}
            reactTableData={{tableHeaderData: columns, tableRowsData: businesses?.map((c) => {
              return {...c,
                img: <TableLogo id={c.id} />,
                is_default: c.is_default ? 'Yes' : 'No',
                director: `${c.director.first_name} ${c.director.last_name}`,
                actions: (
                  <>
                    <ReactTooltip id="creditCardIcon" type="dark">
                      <span style={{ color: 'white' }}>
                        Payment
                      </span>
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="creditCardIcon"
                      style={{border: 0, background: 'transparent', padding: '5px 0 5px 5px'}}>
                      <CreditCardIcon
                        onClick={() => {
                          navigate(`/settings/businesses/${c.id}/methods`, {
                            state: {
                              prevQuery: [{
                                query: location.search,
                                path: location.pathname,
                              }]
                            }
                          });
                        }}
                        size={18}
                        color="#b1c3c8" />
                    </button>
                    <ReactTooltip id="deleteIcon" type="dark">
                      <span style={{ color: 'white' }}>
                        Delete
                      </span>
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="deleteIcon"
                      style={{border: 0, background: 'transparent', padding: '5px 0 5px 5px'}}>
                      <DeleteIcon
                        onClick={() => {
                          setDeleteId(c.id)
                          setConfirmModal(true)}}
                        size={18}
                        color="#b1c3c8" />
                    </button>
                    <ReactTooltip id="editIcon" type="dark">
                      <span style={{ color: 'white' }}>
                        Edit
                      </span>
                    </ReactTooltip>
                    <button
                      data-tip
                      data-for="editIcon"
                      style={{border: 0, background: 'transparent', padding: '5px 0 5px 5px'}}>
                      <PencilIcon
                        onClick={() => {
                          navigate(`/settings/businesses/${c.id}/edit`, {
                            state: {
                              prevQuery: [{
                                query: location.search,
                                path: location.pathname,
                              }]
                            }
                          });
                        }}
                        color="#b1c3c8"
                        size={18}
                      />
                    </button>
                  </>
                )
              }
            })}}
          />

          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>

        <ConfirmModal
          color="primary"
          btn="Default"
          message={`Are you sure you want to delete business #${deleteId}?`}
          toggle={() => setConfirmModal(!confirmModal)}
          modal={confirmModal}
          onNext={handleDeleteBusinesses}
        />
        <CustomModal
          successModal={failedDeleteModal}
          toggleCloseModal={() => setFailedDeleteModal(!failedDeleteModal)}
          textModal={messageDeleteError}
          color={"danger"}
        />
        <CustomModal
          successModal={successDeleteModal}
          toggleCloseModal={() => {
            setSuccessDeleteModal(!successDeleteModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Business №${deleteId} was successfully deleted!`}
          color={"success"}
        />
      </Row>
    </Container>
  );
};

export default Businesses;
