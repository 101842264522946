import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProjectsFetchingAction } from '../../../../redux/actions/projectsActions';
import {
  deleteSubscriptionProject,
  postSubscriptionProject,
} from '../../../../utils/api';
import DataReactTable from '../../../Tables/DataTable/components/DataReactTable';
import { columns } from './AccountProjectsTableColumns';
import CustomModal from '../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import loaderIcon from '../../../../shared/img/loader.gif';


const TableButton = ({isActive, handleSubscribeProject, id, handleUnsubscribeProject, name, setNameProject, disabled, isLoading, loadingAction}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getMetaData = () => {
    const obj = {
      btnLabel: "",
      btnClass: ""
    }

    if(isActive) {
      obj.btnLabel = "Activated";
      obj.btnClass = "btn-activated";
    }
    if(isHovered && isActive) {
      obj.btnLabel = "Deactivate";
      obj.btnClass = "btn-deactivated";
    }

    if(!isActive) {
      obj.btnLabel = "Deactivated";
      obj.btnClass = "btn-deactivated";
    }
    if(!isActive && isHovered) {
      obj.btnLabel = "Activate";
      obj.btnClass = "btn-activated";
    }

    if (isLoading && loadingAction === 'activating') {
      obj.btnLabel = "Activating...";
    }

    if (isLoading && loadingAction === 'deactivating') {
      obj.btnLabel = "Deactivating...";
    }

    return obj;
  }

  const {btnLabel, btnClass} = getMetaData();

  return (
    <button
      className={btnClass}
      disabled={disabled}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setNameProject(name);
        if(!isActive) {
          handleSubscribeProject(id);
        } else {
          handleUnsubscribeProject(id);
        }
      }}
    >{isLoading ? <img src={loaderIcon} alt='Loading' style={{width: "15px", marginRight: "5px"}} /> : null}{btnLabel}</button>
  )
}

const AccountProjects = () => {
  const { t } = useTranslation('common');
  const {projects, errors, totalRecords} = useSelector(state => state.projects);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [query] = useState({start: 0, limit: 10, search_query: ''});
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [messageSubscribeError, setMessageSubscribeError] = useState('');
  const [failedSubscribeModal, setFailedSubscribeModal] = useState(false);
  const [successSubscribeModal, setSuccessSubscribeModal] = useState(false);
  const [messageUnsubscribeError, setMessageUnsubscribeError] = useState('');
  const [failedUnsubscribeModal, setFailedUnsubscribeModal] = useState(false);
  const [successUnsubscribeModal, setSuccessUnsubscribeModal] = useState(false);
  const [nameProject, setNameProject] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loadingProjectId, setLoadingProjectId] = useState(null);
  const [loadingAction, setLoadingAction] = useState('');


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getProjectsFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, query, dispatch, refreshCounter, location.pathname, navigate]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleSubscribeProject = (subscribeId) => {
    setDisabled(true);
    setLoadingProjectId(subscribeId);
    setLoadingAction('activating');
    postSubscriptionProject(subscribeId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setLoadingProjectId(null);
        setDisabled(false);
        setSuccessSubscribeModal(true);
        setTimeout(() => {
          setSuccessSubscribeModal(false);
          setRefreshCounter(refreshCounter + 1);
        }, 1000);
      } else {
        setMessageSubscribeError(res.data.errors[0].message);
        setFailedSubscribeModal(!failedSubscribeModal);
      }
    })
  }

  const handleUnsubscribeProject = (unsubscribeId) => {
    setDisabled(true);
    setLoadingProjectId(unsubscribeId);
    setLoadingAction('deactivating');
    deleteSubscriptionProject(unsubscribeId, authUser.accessToken).then(res => {
      if(res.data.success) {
        setDisabled(false);
        setLoadingProjectId(null);
        setSuccessUnsubscribeModal(true);
        setTimeout(() => {
          setSuccessUnsubscribeModal(false);
          setRefreshCounter(refreshCounter + 1);
        }, 1000)
      } else {
        setMessageUnsubscribeError(res.data.errors[0].message);
        setFailedUnsubscribeModal(!failedUnsubscribeModal);
      }
    })
  }

  return (
    <>
        <DataReactTable
          sortTable={false}
          search={false}
          pagination={{
            onChangePageSize: handleChangePageSize,
            onChangePage: handleChangePage,
            currentPage,
            totalRecords,
            pageLimit
          }}
          emptyStateMessage={t('empty_states.projects')}
          reactTableData={{tableHeaderData: columns, tableRowsData: projects?.map(p => {
            return {
              ...p,
              customers: p?.clients?.map(c => {
                return (
                  <span style={{whiteSpace: "nowrap"}}>
                    {c.first_name + " " + c.last_name} <br />
                  </span>
                )
              }),
              actions:
                <div className={"tableActions"}>
                <TableButton
                  isActive={p.is_subscribed}
                  id={p.id}
                  name={p.name}
                  setNameProject={setNameProject}
                  handleSubscribeProject={handleSubscribeProject}
                  handleUnsubscribeProject={handleUnsubscribeProject}
                  disabled={disabled}
                  isLoading={loadingProjectId === p.id}
                  loadingAction={loadingAction}
                />
              </div>
            }
          })}}
        />

        {
          errors ? (<div>{errors}</div>) : null
        }

        <CustomModal
          successModal={failedSubscribeModal}
          toggleCloseModal={() => setFailedSubscribeModal(!failedSubscribeModal)}
          textModal={messageSubscribeError}
          color={"danger"}
        />
        <CustomModal
          successModal={successSubscribeModal}
          textModal={`Subscribed to ${nameProject}`}
          color={"success"}
        />
        <CustomModal
          successModal={failedUnsubscribeModal}
          toggleCloseModal={() => setFailedUnsubscribeModal(!failedUnsubscribeModal)}
          textModal={messageUnsubscribeError}
          color={"danger"}
        />
        <CustomModal
          successModal={successUnsubscribeModal}
          textModal={`Unsubscribe from project ${nameProject}`}
          color={"success"}
        />
      </>
  );
};

export default AccountProjects;
