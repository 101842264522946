import React from 'react';
import {Modal} from 'reactstrap';
import CustomAlert from '../../MyBankAccount/CustomAlert';


const MyBankAccountModal = ({successModal, toggleCloseModal}) => {
  return (
    <Modal
      isOpen={successModal}
      toggle={toggleCloseModal}
      modalClassName="ltr-support"
      className="modal-dialog  modal-dialog--primary theme-light modal-add-new-variable"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleCloseModal}
        />
      </div>
      <div className="modal__body">
        <CustomAlert
          color="success"
          className="alert--bordered"
        >
          <p>
            <span className="bold-text">Bank Account details have been successfully updated!</span>
          </p>
        </CustomAlert>
      </div>
    </Modal>
  );
};

export default MyBankAccountModal;
