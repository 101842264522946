import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import TopbarSidebarButton from './TopbarSidebarButton';
import { backToLogin } from '../../../utils/helpers';
import { backToAdminRequest } from '../../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
// import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

// import TopbarSearch from './TopbarSearch';


const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user, loginAsBoolean} = useSelector(state => state.auth);
  const [collapse, setCollapse] = useState(false);

  const handleLogOut = () => {
    const allCookies = Cookies.get();

    Object.keys(allCookies).forEach(cookie => {
      Cookies.remove(cookie, { domain: '.clodify.com' });
    });
    localStorage.clear();
    backToLogin();
  }

  const toggle = () => {
    setCollapse(prevState => !prevState);
  };

  return (
    <div className="topbar">
      <div className="topbar__left">
        <TopbarSidebarButton
          changeMobileSidebarVisibility={changeMobileSidebarVisibility}
          changeSidebarVisibility={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/dashboard" />
      </div>
      <div className="topbar__right">
          <div className="topbar__profile">
            <button type="button" className="topbar__avatar" onClick={toggle}>
              <img className="topbar__avatar-img"
                   src={`${user?.userPhoto}` || process.env.PUBLIC_URL + '/img/user-avatar.svg'} alt={'Avatar'} />
                <p className="topbar__avatar-name">
                  <span style={{ whiteSpace: 'nowrap' }}>{`${user.userData.first_name} ${user.userData.last_name}`}</span><br />
                  <span>{user.role}</span>
                </p>
              <ChevronDownIcon className="topbar__icon" />
            </button>

            {collapse && <button type="button" className="topbar__back" onClick={toggle} />}
            <Collapse isOpen={collapse} className="topbar__menu-wrap">
              <div className="topbar__menu">
                <TopbarMenuLink title="Help" icon="inbox" path="/pages/two" />
                <div className="topbar__menu-divider" />
                <TopbarMenuLink title="Log Out" icon="exit" path="/" onClick={handleLogOut} />
              </div>
            </Collapse>
          </div>

          {/*<button className="log-out" onClick={handleLogOut}>*/}
          {/*  <LogoutVariantIcon color="#b1c3c8" size={24} />*/}
          {/*</button>*/}
          {/*<div className="topbar__right-search">*/}
          {/*<TopbarSearch />*/}
          {/*</div>*/}
          {
            loginAsBoolean ? (
              <div className="d-none d-md-flex justify-content-end align-items-center" style={{ paddingLeft: '17px' }}>
                <button
                  className="btn btn-primary mb-0"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => dispatch(backToAdminRequest(navigate))}
                >
                  Login back as ADMIN
                </button>
              </div>
            ) : null
          }
        </div>
    </div>
  )
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
