import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const SidebarCategory = ({
  title, icon, isNew, children, sidebarCollapse, route, skipBaseName, onClick, defaultActive = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const categoryClass = classNames({
    'sidebar__category-wrap': true,
    'sidebar__category-wrap--open': isCollapsed,
    'sidebar__link sidebar__category': true,
  });

  const collapseSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (defaultActive && !isInit) {
      setIsCollapsed(true);
      setIsInit(true);
    }
  }, [defaultActive]);

  return (
    <div className={sidebarCollapse ? 'sidebar-collapse-wrapper' : ''}>
      <button className={categoryClass} type="button" onClick={collapseSidebar}>
        {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
        {route ? (
          <NavLink to={route} onClick={onClick}>
            <p className="sidebar__link-title">{title}
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </NavLink>
        ) : (
          <p className="sidebar__link-title">{title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
        )}
        {children ? <span className='sidebar__category-icon lnr lnr-chevron-right' /> : null}
      </button>
      {
        children ? (
          <Collapse isOpen={sidebarCollapse || isCollapsed} className="sidebar__submenu-wrap">
            <ul className="sidebar__submenu">
              <div>
                {children}
              </div>
            </ul>
          </Collapse>
        ) : null
      }
    </div>
  );
};

SidebarCategory.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isNew: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sidebarCollapse: PropTypes.bool,
  route: PropTypes.string,
  skipBaseName: PropTypes.bool,
};

SidebarCategory.defaultProps = {
  icon: '',
  isNew: false,
  route: '',
  skipBaseName: false,
};

export default SidebarCategory;
