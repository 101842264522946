import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, ButtonToolbar,
    Card, CardBody, Col, Table,
} from 'reactstrap';
import {useLocation, useNavigate, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getClientModulesFetchingAction,
} from '../../../../../../redux/actions/clientModulesAction';
import Loading from "../../../../../../shared/components/Loading";
import DeleteIcon from "mdi-react/DeleteIcon";
import {header} from "./DataTable";
import {postRemoveClientModules} from "../../../../../../utils/api";
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { getRemovedClientModulesId } from '../../../../../../redux/actions/modulesActions';
import ConfirmModal from '../../../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';



const MyTable = () => {
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    const {id} = useParams();
    const location = useLocation();
    const {clientModules, loading, errors} = useSelector(state => state.clientModules);
    const dispatch = useDispatch();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [moduleId, setModuleId] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const authUser = useSelector(state => state.auth.user);

    const handleRefresh = () => {
        setRefreshCounter(refreshCounter + 1);
    };

    useEffect(() => {
        dispatch(getClientModulesFetchingAction(id));
    }, [refreshCounter, dispatch, id]);

    const prevNavigationQuery = location.state?.prevQuery ? [...location.state.prevQuery] : [];
    prevNavigationQuery.push({
        query: location.search,
        path: location.pathname,
    });

    const deleteModules = () => {
      postRemoveClientModules(moduleId, id, authUser.accessToken, authUser.clientToken).then(res => {
          if (res.data.success) {
              dispatch(getRemovedClientModulesId(res.data.data.module_id));
              handleRefresh();
          } else {
              setErrorMessage(res.data.errors[0].message);
              setModal(true);
          }
      }).catch(err => {
          setErrorMessage(err.response.data.errors.message);
          setModal(true);
      });
    }

    const renderTableBody = () => {
      if (clientModules?.length === 0) {
        return (
          <tbody className="table table--empty">
            <tr>
              <td colspan="100%">
                <p>{t('empty_states.modules')}</p>
              </td>
            </tr>
          </tbody>
        );
      }

      return (
        <tbody>
          {clientModules?.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.is_basic}</td>
              <td>{item.price}</td>
              <td>
                {item.key !== 'basic' ? (
                  <button
                    style={{ border: 0, background: 'transparent' }}
                    onClick={() => {
                      setModuleId(item.id);
                      setConfirmDeleteModal(true);
                    }}
                  >
                    <DeleteIcon color="#b1c3c8" size={18} />
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      );
    };

    return (
        <>
            <Col sm={12}>
                <ButtonToolbar className="form__button-toolbar" style={{justifyContent: "flex-end"}}>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={() => {
                          navigate(`/settings/core-system/saas/${id}/module`, {
                            state: { prevQuery: prevNavigationQuery, }
                          });
                        }}
                    >
                        Add New Module
                    </Button>
                </ButtonToolbar>
            </Col>
            <Col sm={12}>
                {loading ? (<Loading/>) : (
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">Modules</h5>
                            </div>
                            <Table className="table--bordered" responsive>
                                <thead>
                                <tr>
                                    {header.map(item => (
                                        <th key={item.id}>{item.title}</th>
                                    ))}
                                </tr>
                                </thead>
                               {renderTableBody()}
                            </Table>
                        </CardBody>
                    </Card>
                )}
                <CustomModal
                  successModal={modal}
                  toggleCloseModal={() => setModal(!modal)}
                  textModal={errorMessage}
                  color={"danger"}
                />
                <ConfirmModal
                  color="danger"
                  btn="Default"
                  message={`Are you sure you want to delete client module #${moduleId}?`}
                  toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
                  modal={confirmDeleteModal}
                  onNext={deleteModules}
                />
                {
                    errors ? (<div>{errors}</div>) : null
                }
            </Col>
        </>

    );
};

export default MyTable;
