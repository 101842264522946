import AppDashboard from '../../../Dashboards/App';
import SaasDashboard from '../../../Settings/Saas';
import ClientModules from '../../../Settings/Saas/components/ClientModules/ClientModules';
import FormAddModule from '../../../Settings/Saas/components/ClientModules/FormAddModule/FormAddModule';
import BookingDashboard from '../../../Dashboards/Booking';
import MySalary from '../../../MyAccount/MySalary/MySalary';
import FinancialReports from '../../../Financials/Reports/index';
import StatisticProjects from '../../../Dashboards/Statistics/StatisticProjects';
import Subcontractors from '../../../Financials/Reports/components/Subcontractors/Subcontractors';
import Roles from '../../../../config/roles';
import Environments from '../../../Management/Projects/components/Environments/Environments';
import Projects from '../../../Management/Projects/index';
import Variables from '../../../Management/Projects/components/Environments/Variables/Variables';
import ProjectsEdit from '../../../Management/Projects/components/ProjectsEdit/ProjectsEdit';
import AddNewLead from '../../../CRM/AddNewLead/AddNewLead';
import LeadsPage from '../../../CRM/LeadsPage/LeadsPage';
import SalaryReports from '../../../Financials/SalaryReports/SalaryReports';
import SalaryReportsList from '../../../Financials/SalaryReports/SalaryReportsList';
import SalaryReportEdit from '../../../Financials/SalaryReports/SalaryReportEdit';
import LeadsSingle from '../../../CRM/LeadsSingle';
import MyBankAccount from '../../../MyAccount/MyBankAccount/MyBankAccount';
import EmailTemplates from '../../../Settings/EmailTemplates/EmailTemplates';
import MyInvoices from '../../../MyAccount/MyInvoices/MyInvoices';
import FinancialReportEdit from '../../../Financials/Reports/components/FinancialReportEdit/FinancialReportEdit';
import FinancialReportIncomes from '../../../Financials/Reports/components/FinancialReportIncome/FinancialReportIncomes';
import FinancialReportAutomaticIncome from '../../../Financials/Reports/components/FinancialReportAutomaticIncome';
import FinancialReportAddIncome from '../../../Financials/Reports/components/FinancialReportAutomaticIncome/FinancialReportAddIncome';
import YearlyStatistics from '../../../Dashboards/Statistics/YearlyStatistics';
import Invoices from '../../../Financials/Invoices';
import AddInvoices from '../../../Financials/Invoices/components/AddInvoices';
import FinancialReportStepsTwoAutomaticIncome from '../../../Financials/Reports/components/FinancialReportAutomaticIncome/FinancialReportStepsTwoAutomaticIncome';
import VacationsHistory from '../../../MyAccount/VacationsHistory';
import EditInvoice from '../../../Financials/Invoices/components/EditInvoice';
import Users from '../../../Management/Users';
import CreateUser from '../../../Management/Users/components/CreateUser';
import EditUser from '../../../Management/Users/components/EditUser';
import WorkHistory from '../../../Management/Users/components/WorkHistory';
import CreateWorkHistory from '../../../Management/Users/components/WorkHistory/CreateWorkHistory/CreateWorkHistory';
import CreateSalaryReports from '../../../Financials/SalaryReports/components/CreateSalaryReports';
import ProjectsAdd from '../../../Management/Projects/components/ProjectsAdd/ProjectsAdd';
import CreateSalaryList from '../../../Financials/SalaryReports/components/CreateSalaryList/CreateSalaryList';
import MyReports from '../../../MyAccount/Reports/MyReports';
import System from '../../../Settings/System';
import FinancialActivity from '../../../Financials/Activity';
import Reports from '../../../Management/Reports';
import MyReviews from '../../../MyAccount/MyReviews';
import Businesses from '../../../Settings/Businesses';
import AddNewBusinesses from '../../../Settings/Businesses/components/AddNewBusinesses/AddNewBusinesses';
import EditBusinesses from '../../../Settings/Businesses/components/EditBusinesses/EditBusinesses';
import BusinessesPaymentMethods from '../../../Settings/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethods';
import BusinessesPaymentMethodAdd from '../../../Settings/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethodAdd/BusinessesPaymentMethodAdd';
import BusinessesPaymentMethodEdit from '../../../Settings/Businesses/components/BusinessesPaymentMethods/BusinessesPaymentMethodEdit/BusinessesPaymentMethodEdit';
import Account from '../../../MyAccount/Account';
import BankAccount from '../../../MyAccount/Reports/BankAccount';

export const routes = [
  { path: '/dashboard', name: 'Dashboard', Component: AppDashboard },
  {
    path: '/dashboard/project-statistics',
    name: 'Project statistics',
    Component: StatisticProjects,
    permission: [Roles.ADMIN, Roles.FIN, Roles.SALES],
  },
  {
    path: '/dashboard/yearly-statistics',
    name: 'Yearly statistics',
    Component: YearlyStatistics,
    permission: [Roles.ADMIN],
  },
  {
    path: '/dashboard/booking_dashboard',
    name: 'Booking dashboard',
    Component: BookingDashboard,
  },
  {
    path: '/account/submit-a-report',
    name: 'Submit a Report',
    Component: MyReports,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.DEV, Roles.PM],
  },
  {
    path: '/account/payouts',
    name: 'Payouts',
    Component: MySalary,
  },
  {
    path: '/account/invoices',
    name: 'Invoices',
    Component: MyInvoices,
    permission: [
      Roles.ADMIN,
      Roles.SALES,
      Roles.FIN,
      Roles.DEV,
      Roles.PM,
      Roles.GUEST,
    ],
  },
  {
    path: '/account/used-vacations',
    name: 'Used Vacations',
    Component: VacationsHistory,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.DEV, Roles.PM],
  },
  {
    path: '/account/profile',
    name: 'Profile',
    Component: Account,
    exec: true,
  },
  {
    path: '/account/reviews',
    name: 'Reviews',
    Component: MyReviews,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.DEV, Roles.PM],
  },
  {
    path: '/account/bank-account',
    name: 'Bank Account',
    Component: BankAccount,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.DEV, Roles.PM],
  },
  {
    path: '/account/my-bank-account',
    name: 'My Bank Account',
    Component: MyBankAccount,
    permission: [Roles.CLIENT],
  },
  {
    path: '/crm/your-leads',
    name: 'Your Leads',
    Component: LeadsPage,
    permission: [Roles.ADMIN, Roles.SALES],
  },
  {
    path: '/crm/your-leads/:id',
    name: 'Lead #id',
    Component: LeadsSingle,
    permission: [Roles.ADMIN, Roles.SALES],
  },
  {
    path: '/crm/add-a-new-lead',
    name: 'Add a New Lead',
    Component: AddNewLead,
    permission: [Roles.ADMIN, Roles.SALES],
  },
  {
    path: '/management/reports',
    name: 'Reports',
    Component: Reports,
    exec: true,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },
  {
    path: '/management/users',
    name: 'Users',
    Component: Users,
  },
  {
    path: '/management/users/add',
    name: 'Create User',
    Component: CreateUser,
  },
  {
    path: '/management/users/:id/edit',
    name: 'Edit User',
    Component: EditUser,
  },
  {
    path: '/management/users/:id/work-history',
    name: 'Work History',
    Component: WorkHistory,
  },
  {
    path: '/management/users/:id/work-history/add',
    name: 'Create Work History',
    Component: CreateWorkHistory,
  },
  {
    path: '/management/projects',
    name: 'Projects',
    Component: Projects,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },
  {
    path: '/management/projects/add',
    name: 'Create Project',
    Component: ProjectsAdd,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },
  {
    path: '/management/projects/:id/edit',
    name: 'Edit Project #id',
    Component: ProjectsEdit,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },
  {
    path: '/management/projects/:id/environments',
    name: 'Environments',
    Component: Environments,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },
  {
    path: '/management/projects/:id/environments/:env_id/variables',
    name: 'Environment #env_id Variables',
    Component: Variables,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.CLIENT],
  },

  {
    path: '/financials/invoices',
    name: 'Invoices',
    Component: Invoices,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/invoices/add',
    name: 'Invoices Add',
    Component: AddInvoices,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/invoices/:id/edit',
    name: 'Invoice Edit',
    Component: EditInvoice,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/salaries',
    name: 'Salaries',
    Component: SalaryReports,
    permission: [Roles.ADMIN, Roles.FIN],
  },
  {
    path: '/financials/salaries/add',
    name: 'Add',
    Component: CreateSalaryReports,
    permission: [Roles.ADMIN, Roles.FIN],
  },
  {
    path: '/financials/salaries/:id/edit',
    name: 'Edit',
    Component: SalaryReportsList,
    permission: [Roles.ADMIN, Roles.FIN],
  },
  {
    path: '/financials/salaries/:id/edit/add-list',
    name: 'Create Salary List',
    Component: CreateSalaryList,
    permission: [Roles.ADMIN, Roles.FIN],
  },
  {
    path: '/financials/salaries/:id/edit/:user_id/edit-list',
    name: 'Edit Salary List',
    Component: SalaryReportEdit,
    permission: [Roles.ADMIN, Roles.FIN],
  },
  {
    path: '/financials/reports',
    name: 'Reports',
    Component: FinancialReports,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:id/edit',
    name: 'report #id',
    Component: FinancialReportEdit,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:id/incomes',
    name: 'Incomes',
    Component: FinancialReportIncomes,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:id/automatic-income',
    name: 'Automatic Income',
    Component: FinancialReportAutomaticIncome,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:report_id/automatic-income/:id',
    name: 'Invoices',
    Component: FinancialReportStepsTwoAutomaticIncome,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:id/incomes/income',
    name: 'Add a New Income Item',
    Component: FinancialReportAddIncome,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/reports/:id/subcontractors',
    name: 'Subcontractors',
    Component: Subcontractors,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN],
  },
  {
    path: '/financials/activity',
    name: 'Activity',
    Component: FinancialActivity,
    permission: [Roles.ADMIN],
  },

  {
    path: '/settings/system-variables',
    name: 'System Variables',
    Component: System,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses',
    name: 'Businesses',
    Component: Businesses,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses/add',
    name: 'Add New Business',
    Component: AddNewBusinesses,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses/:id/edit',
    name: 'Edit Business',
    Component: EditBusinesses,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses/:id/methods',
    name: 'Payment Methods',
    Component: BusinessesPaymentMethods,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses/:id/methods/add',
    name: 'Add a new Payment Method',
    Component: BusinessesPaymentMethodAdd,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/businesses/:id/methods/:method_id/edit',
    name: 'Edit Payment Method',
    Component: BusinessesPaymentMethodEdit,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/email-templates',
    name: 'Email Templates',
    Component: EmailTemplates,
    permission: [Roles.ADMIN, Roles.SALES, Roles.FIN, Roles.DEV, Roles.PM],
  },

  {
    path: '/settings/core-system/saas',
    name: 'Clients',
    Component: SaasDashboard,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/core-system/saas/:id',
    name: 'Client modules',
    Component: ClientModules,
    permission: [Roles.ADMIN],
  },
  {
    path: '/settings/core-system/saas/:id/module',
    name: 'Add New Module',
    Component: FormAddModule,
    permission: [Roles.ADMIN],
  },
];
