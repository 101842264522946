import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getEmailTemplatesFetchingActions } from '../../../redux/actions/emailTemplatesActions';
import Loading from '../../../shared/components/Loading';
import { Col, Container, Row } from 'reactstrap';
import Template from './Template';

const EmailTemplates = () => {
  const { t } = useTranslation('common');
  let { emailTemplates, loading, errors } = useSelector((state) => state.emailTemplates);
  const dispatch = useDispatch();
  const [refreshTemplates, setRefreshTemplates] = useState(0);

  useEffect(() => {
    dispatch(getEmailTemplatesFetchingActions());
  }, [refreshTemplates, dispatch])

  const refresh = () => {
    setRefreshTemplates(refreshTemplates + 1);
  }

  const renderContent = () => {
    if (loading) {
      return <Loading/>;
    }

    if (!emailTemplates || emailTemplates.length === 0) {
      return <p>{t('empty_states.default')}</p>
    }

    return emailTemplates?.map((b, i) => {
      return (
        <Template templates={b} key={i} refresh={refresh}/>
      )
    });
  }

  return (
    <Container className="email-templates">
      <Row>
        <Col>
          {renderContent()}
          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>
      </Row>
    </Container>
  );
};

export default EmailTemplates;
