import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router';

import SidebarLink from './SidebarLink';
import Roles from '../../../config/roles';
import SidebarCategory from "./SidebarCategory";
import { routes } from '../../App/Router/WrappedRoutes/routesConfig';

const SidebarContent = ({
  onClick, sidebarCollapse
}) => {
  const userRole = useSelector(state => state.auth.user.role);
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);
  const pathname = route.path;
  const [initialRoute, setInitialRoute] = useState('');

  useEffect(() => {
    setInitialRoute(pathname);
  }, []);

  const hideSidebar = () => {
    onClick();
  };

  const renderDashboard = () => {
    return (
      <SidebarCategory
        title="Dashboard"
        route="/dashboard"
        icon="home"
        onClick={hideSidebar}
        sidebarCollapse={sidebarCollapse}
      />
    )
  }

  const renderProfile = () => {
    if (userRole !== Roles.CLIENT && userRole !== Roles.GUEST) {
      return (
        <SidebarCategory
          defaultActive={initialRoute.includes('account')}
          title="My Account"
          icon="list"
          onClick={hideSidebar}
          sidebarCollapse={sidebarCollapse}>
          {
            userRole !== Roles.CLIENT && (
              <SidebarLink
                title="Submit a Report"
                route="/account/submit-a-report"
                onClick={hideSidebar}
              />
            )
          }
          {
            (userRole !== Roles.CLIENT || userRole !== Roles.GUEST) && (
              <SidebarLink
                title="Payouts"
                route="/account/payouts"
                onClick={hideSidebar}
              />
            )
          }
          {
            userRole !== Roles.CLIENT ? (
              <SidebarLink
                title="Invoices"
                route="/account/invoices"
                onClick={hideSidebar}
              />
            ) : null
          }
          {
            (userRole !== Roles.GUEST && userRole !== Roles.CLIENT) && (
              <SidebarLink
                title="Used Vacations"
                route="/account/used-vacations"
                onClick={hideSidebar}
              />
            )
          }
          <SidebarLink
            title="Profile"
            route="/account/profile"
            onClick={hideSidebar}
          />
          {
            (userRole !== Roles.CLIENT) && (
              <SidebarLink
                title="Reviews"
                route="/account/reviews"
                onClick={hideSidebar}
              />
            )
          }
          {
            (userRole !== Roles.CLIENT || userRole !== Roles.GUEST) && (
              <SidebarLink
                title="Bank Account"
                route="/account/bank-account"
                onClick={hideSidebar}
              />
            )
          }
          {
            (userRole === Roles.CLIENT) && (
              <SidebarLink
                title="My Bank Account"
                route="/account/my-bank-account"
                onClick={hideSidebar}
              >
              </SidebarLink>
            )
          }
        </SidebarCategory>
      );
    }
  };

  const renderCrm = () => {
    if (userRole === Roles.ADMIN || userRole === Roles.SALES) {
      return (
        <SidebarCategory
          defaultActive={initialRoute.includes('crm')}
          title="CRM"
          icon="list"
          onClick={hideSidebar}
          sidebarCollapse={sidebarCollapse}>
          <SidebarLink
            title="Your Leads"
            route="/crm/your-leads"
            onClick={hideSidebar}
            isQuestionIcon={true}
            tooltipText={"Access and track all your potential clients and business opportunities."}
          />
          <SidebarLink
            title="Add New Lead"
            route="/crm/add-a-new-lead"
            onClick={hideSidebar}
            isQuestionIcon={true}
            tooltipText={"Add a new lead and track your sales progress!"}
          />
        </SidebarCategory>
      );
    }
  }

  const renderManagement = () => {
    return (
      <SidebarCategory
        defaultActive={initialRoute.includes('management')}
        title="Management"
        icon="list"
        onClick={hideSidebar}
        sidebarCollapse={sidebarCollapse}>
        {(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES || userRole === Roles.CLIENT) && (
          <SidebarLink
            title="Reports"
            route="/management/reports"
            onClick={hideSidebar}
          />
        )}
        <SidebarLink
          title="Users"
          route="/management/users"
          onClick={hideSidebar}
        />
        {(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES || userRole === Roles.CLIENT) && (
          <SidebarLink
            title="Projects"
            route="/management/projects"
            onClick={hideSidebar}
          />
        )}
      </SidebarCategory>
    );
  }

  const renderFinancials = () => {
    return (
      <SidebarCategory
        defaultActive={initialRoute.includes('financials')}
        title="Financials"
        icon="list"
        onClick={hideSidebar}
        sidebarCollapse={sidebarCollapse}>
        {
          (userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && (
            <SidebarLink
              title="Invoices"
              route="/financials/invoices"
              onClick={hideSidebar}
            />
          )
        }
        {
          (userRole === Roles.ADMIN || userRole === Roles.FIN) && (
            <SidebarLink
              title="Salaries"
              route="/financials/salaries"
              onClick={hideSidebar}
            />
          )
        }
        {
          (userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && (
            <SidebarLink
              title="Reports"
              route="/financials/reports"
              onClick={hideSidebar}
            />
          )
        }
        {
          (userRole === Roles.ADMIN) && (
            <SidebarLink
              title="Activity"
              route="/financials/activity"
              onClick={hideSidebar}
            />
          )
        }
      </SidebarCategory>
    );
  }

  const renderSettings = () => {
    return (
      (userRole === Roles.ADMIN || userRole === Roles.CLIENT) && (
        <SidebarCategory
          defaultActive={initialRoute.includes('settings')}
          title="Settings"
          icon="list"
          onClick={hideSidebar}
          sidebarCollapse={sidebarCollapse}
        >
          {
            (userRole === Roles.ADMIN) && (
              <SidebarLink
                title="System Variables"
                route="/settings/system-variables"
                onClick={hideSidebar}
              />
            )
          }
          {
            (userRole === Roles.ADMIN) && (
              <SidebarLink
                title="Businesses"
                route="/settings/businesses"
                onClick={hideSidebar}
              />
            )
          }
          {
            (userRole === Roles.ADMIN) && (
              <>
                <SidebarLink
                  title="Email Templates"
                  route="/settings/email-templates"
                  onClick={hideSidebar}
                />
                <SidebarCategory
                  title="Core System"
                  onClick={hideSidebar}
                  sidebarCollapse={sidebarCollapse}
                >
                  <SidebarLink
                    title="SaaS"
                    route="/settings/core-system/saas"
                    onClick={hideSidebar}
                  />
                </SidebarCategory>
              </>
            )
          }

        </SidebarCategory>
      )
    )

  }

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {renderDashboard()}
        {renderProfile()}
        {renderCrm()}
        {renderManagement()}
        {(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && renderFinancials()}
        {renderSettings()}
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
