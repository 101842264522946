import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SelectUsersForm from './SelectUsersForm';
import { getAllUsers } from '../../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './vacationsHistoryColumns';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { getVacationsHistoryFetchingAction } from '../../../redux/actions/vacationsHistoryActions';
import { reset } from 'redux-form';
import _ from "lodash";
import Roles from '../../../config/roles';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';


const VacationsHistory = () => {
  const { t } = useTranslation('common');
  const {vacationsHistory, errors, totalRecords} = useSelector(state => state.vacationsHistory);
  const user = useSelector(state => state.auth.user);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [query, setQuery] = useState({start: 0, limit: 10, search_query: '', user_id: '', year: ''});
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);

  useEffect(() => {
    getAllUsers(user.accessToken).then(res => setUsers(res.data.data.users))
  }, [user.accessToken]);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getVacationsHistoryFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, dispatch, query, location.pathname, navigate]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setQuery({...query, user_id: selectedUserId});
  }

  const handleClearSubmitForm = () => {
    dispatch(reset('form_select_users'));
    setQuery({...query, user_id: ''});
  }

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    setQuery({...query, search_query: value});
  }, 600)

  return (
    <Container>
      <Row>
        <Col className="col-12">
          {
            (user.role === Roles.ADMIN || user.role === Roles.FIN) && (
              <SelectUsersForm handleSubmit={handleSubmitForm} users={users} setSelectedUserId={setSelectedUserId} handleClearSubmitForm={handleClearSubmitForm} />
            )
          }
        </Col>
        <Col className="col-12">
          <DataReactTable
            tableName={"Vacations history"}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            }}
            sortTable={true}
            search={true}
            searchValue={getSearchValue}
            emptyStateMessage={t('empty_states.history')}
            reactTableData={{tableHeaderData: columns, tableRowsData: vacationsHistory.map((c) => {
              return {
                ...c,
                user: `${c.user.first_name} ${c.user.last_name}`,
                days: `${c.days}`
              }
              })}}
          />
        </Col>
        {
          errors ? (<div>{errors}</div>) : null
        }
      </Row>
    </Container>
  );
};

export default VacationsHistory;
