import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import BusinessesPaymentMethodsAddForm from './BusinessesPaymentMethodAddForm/BusinessesPaymentMethodAddForm';
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useNavigate, useParams } from 'react-router-dom';
import { postBusinessPaymentMethods } from '../../../../../../utils/api';

const BusinessesPaymentMethodAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authUser = useSelector(state => state.auth.user);
  const [successAddedModal, setSuccessAddedModal] = useState(false);
  const [methodId, setMethodId] = useState(null);
  const [failedAddedModal, setFailedAddedModal] = useState(false);
  const [messageAddedError, setMessageAddedError] = useState('');
  const handleSubmit = (values) => {
    const newValues = {...values, business_id: id};

    postBusinessPaymentMethods(id, newValues, authUser.accessToken).then(res => {
      if(res.data.success) {
        setMethodId(res.data.data.payment_method_id);
        setSuccessAddedModal(!successAddedModal);
      } else {
        setMessageAddedError(res.data.errors[0].message);
        setFailedAddedModal(!failedAddedModal);
      }
    })
  }

  return (
    <Container>
      <Row>
        <BusinessesPaymentMethodsAddForm onSubmit={handleSubmit} />
      </Row>

      <CustomModal
        successModal={successAddedModal}
        toggleCloseModal={() => {
          setSuccessAddedModal(!successAddedModal);
          navigate(`/settings/businesses/${id}/methods`);
        }}
        textModal={`Payment method #${methodId} was successfully added!`}
        color={"success"}
      />
      <CustomModal
        successModal={failedAddedModal}
        toggleCloseModal={() => setFailedAddedModal(!failedAddedModal)}
        textModal={messageAddedError}
        color={"danger"}
      />
    </Container>
  );
};

export default BusinessesPaymentMethodAdd;
