import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Col } from 'reactstrap';
import { renderField } from '../../../../../utils/helpers';
import validate from './validate';
import { connect } from 'react-redux';


let AccountGeneralForm = ({handleSubmit}) => {
  return (
    <form className="form form--myInvoice" onSubmit={handleSubmit}>
      <Col className="col-12" sm={3}>
        <div className='form__form-group'>
          <span className='form__form-group-label'>First Name</span>
          <div className='form__form-group-field'>
            <Field
              name='first_name'
              component={renderField}
              type='input'
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" sm={3}>
        <div className='form__form-group'>
          <span className='form__form-group-label'>Last Name</span>
          <div className='form__form-group-field'>
            <Field
              name='last_name'
              component={renderField}
              type='input'
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" sm={3}>
        <div className='form__form-group'>
          <span className='form__form-group-label'>Email</span>
          <div className='form__form-group-field'>
            <Field
              name='email'
              component={renderField}
              type='input'
              disabled={true}
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" sm={3}>
        <div className='form__form-group'>
          <span className='form__form-group-label'>Phone</span>
          <div className='form__form-group-field'>
            <Field
              name='phone'
              component={renderField}
              type='input'
            />
          </div>
        </div>
      </Col>
      <Col className="col-12" sm={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">Skills</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <Field
                name="skills"
                component="textarea"
                rows={5}
              />
            </div>
          </div>
        </div>
      </Col>
      <Col className="col-12" sm={6}>
        <div className="form__form-group">
          <span className="form__form-group-label">About</span>
          <div className="form__form-group-field">
            <div className="form__form-group-input-wrap">
              <Field
                name="about"
                component="textarea"
                rows={5}
              />
            </div>
          </div>
        </div>
      </Col>
      <Col className="col-12">
        <ButtonToolbar className="form__button-toolbar w-100 justify-content-end">
          <Button color="primary" type="submit">Save</Button>
        </ButtonToolbar>
      </Col>
    </form>
  );
};

AccountGeneralForm = reduxForm({
  form: 'accountGeneralForm',
  enableReinitialize: true,
  validate
})(AccountGeneralForm);

AccountGeneralForm = connect(
  state => ({
    initialValues: {
      skills: state.bankAccount.bankAccount.tags,
      ...state.bankAccount.bankAccount
    }
  })
)(AccountGeneralForm);

export default AccountGeneralForm;
