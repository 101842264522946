import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { useSelector, useDispatch } from 'react-redux';
import { getClientsFetchingAction } from '../../../redux/actions/clientsActions';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import EditForm from './components/EditForm/EditForm';
import { putClient, deleteClient } from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { columns } from './components/ClientsDataColumns';
import _ from 'lodash';
import ConfirmModal from '../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import ReactTooltip from 'react-tooltip';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';
import { parse, stringify } from 'query-string';


const SaasDashboard = () => {
  const { t } = useTranslation('common');
  let navigate = useNavigate();
  const { clients, errors, totalRecords } = useSelector(state => state.clients);
  const authUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [editClient, setEditClient] = useState({});
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({ start: 0, limit: 10, search_query: '', user_id: '' });
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [errorEditMessage, setErrorEditMessage] = useState("");
  const [modalEditFailed, setModalEditFailed] = useState(false);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getClientsFetchingAction(updatedQuery));
  }, [currentPage, pageLimit, refreshCounter, dispatch, query, location.pathname, navigate]);

  const handleOnEdit = (values) => {
    putClient(values, authUser.clientToken)
      .then(res => {
        if(res.data.success) {
          setEditClient({});
          setRefreshCounter(refreshCounter + 1);
          return;
        }

        setErrorEditMessage(res.data.errors[0].message);
        setModalEditFailed(!modalEditFailed);
      });
  };

  const handleOnDelete = () => {
    deleteClient(deleteId, authUser.clientToken).then(_ => {
      setRefreshCounter(refreshCounter + 1);
    });
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const getSearchValue = _.debounce((value) => {
    if (value === "0") return;

    setQuery({...query, search_query: value});
  }, 600)


  const toggle = () => {
    setModal(!modal);
  }

  return (
    <Container className='dashboard'>
      {editClient.id ? (<div onClick={() => setEditClient({})} className='background' />) : null}
      {editClient.id ? (<div className='form-wrapper'><EditForm onSubmit={handleOnEdit} client={editClient}
                                                                handleHideEditForm={() => setEditClient({})} />
      </div>) : null}
      <Row>
        <Col md={12}>
          <h3 className='page-title'>{`SaaS`}</h3>
        </Col>
        <Col md={12}>
          <DataReactTable
            tableName={'Clients'}
            sortTable={true}
            search={true}
            searchValue={getSearchValue}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            }}
            emptyStateMessage={t('empty_states.clients')}
            reactTableData={{
              tableHeaderData: columns, tableRowsData: clients?.map((c) => {
                return {
                  ...c, id: `${c.id}`,
                  actions:
                    <>
                      <ReactTooltip id="deleteIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.delete")}
                        </span>
                      </ReactTooltip>
                      <button
                        style={{border: 0, background: "transparent", paddingLeft: 0}}
                        data-tip
                        data-for="deleteIcon"
                      >
                        <DeleteIcon
                          color="#b1c3c8"
                          size={18}
                          onClick={() => {
                            setDeleteId(c.id)
                            setModal(true)
                          }}
                        />
                      </button>
                      <ReactTooltip id="viewIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.clientModulesSettings")}
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="viewIcon"
                        style={{border: 0, background: "transparent"}}>
                        <EyeIcon
                          onClick={() => {
                            navigate(`/settings/core-system/saas/${c.id}`, {
                              state: {
                                prevQuery: [{
                                  query: location.search,
                                  path: location.pathname,
                                }]
                              }
                            });
                          }}
                          color="#b1c3c8"
                          size={18}
                        />
                      </button>
                      <ReactTooltip id="editIcon" type="dark">
                        <span style={{ color: 'white' }}>
                          {t("reactTooltips.edit")}
                        </span>
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="editIcon"
                        style={{border: 0, background: "transparent"}}
                        onClick={() => setEditClient(c)}>
                        <PencilIcon color="#b1c3c8" size={18} />
                      </button>
                    </>,
                };
              }),
            }} />
          {
            errors ? (<div>{errors}</div>) : null
          }
          <ConfirmModal
            color="danger"
            btn="Default"
            message={`Are you sure you want to delete this client?`}
            toggle={toggle}
            modal={modal}
            onNext={handleOnDelete}
          />
          <CustomModal
            successModal={modalEditFailed}
            toggleCloseModal={() => setModalEditFailed(!modalEditFailed)}
            textModal={errorEditMessage}
            color={"danger"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SaasDashboard;
