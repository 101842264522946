import React from 'react';
import { Button, ButtonToolbar, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../shared/components/form/Select';


const SelectUsersForm = ({handleSubmit, users, setSelectedUserId, handleClearSubmitForm}) => {
  return (
    <form className="form vacations-history" onSubmit={handleSubmit}>
      <Col sm={5} xl={2} lg={3} md={4} className="col-12 xxl-1 pl-0 pr-0 pr-sm-3">
        <div className="form__form-group">
          <span className="form__form-group-label">Select User</span>
          <div className="form__form-group-field">
            <Field
              name="select"
              component={renderSelectField}
              id="users"
              placeholder={"All"}
              options={[{id: '', first_name: "All", last_name: ""}, ...users || []]?.map(u => {
                return {
                  value: u.id,
                  label: `${u.first_name} ${u.last_name}`
                }
              })}
              onChange={(e) => setSelectedUserId(e.value)}
            />
          </div>
        </div>
      </Col>
      <Col sm={7} xl={10} lg={9} md={8} className="col-12 xxl-11 d-flex justify-content-end align-items-center mb-5 mb-sm-0 pr-0 pl-0 pl-sm-3">
        <ButtonToolbar className="form__button-toolbar mb-0">
          <Button color="secondary" type="button" style={{margin: "0 15px 0 0", padding: "8px 25px"}} onClick={() => handleClearSubmitForm()}>
            Clear filters
          </Button>
        </ButtonToolbar>
        <ButtonToolbar className="form__button-toolbar mb-0 mt-0">
          <Button color="primary" type="submit" style={{margin: "0 0", padding: "8px 25px"}}>
            Apply filters
          </Button>
        </ButtonToolbar>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: 'form_select_users', // a unique identifier for this form
})(SelectUsersForm);
