import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import FormCreateSalaryReports from './FormCreateSalaryReports/FormCreateSalaryReports';
import { postSalaryReports } from '../../../../../utils/api';
import CustomModal from '../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CreateSalaryReports = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const authUser = useSelector(state => state.auth.user);

  const createSalaryReport = (value) => {
    postSalaryReports(value, authUser.accessToken).then(res => {
      if(res.data.success !== true) {
        setErrorMessage(res.data.errors[0].message);
        setErrorModal(!errorModal);
      } else {
        setSuccessModal(!successModal);
      }
    })
  }

  return (
    <Container>
      <Row>
        <Col>
          <FormCreateSalaryReports onSubmit={createSalaryReport} />

          <CustomModal
            successModal={errorModal}
            toggleCloseModal={() => setErrorModal(!errorModal)}
            textModal={errorMessage}
            color={"danger"}
          />
          <CustomModal
            successModal={successModal}
            toggleCloseModal={() => {
              setSuccessModal(!successModal);
              navigate("/financials/salaries")
            }}
            textModal={`Salary report was successfully added!`}
            color={"success"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateSalaryReports;
