import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import AccountGeneral from './AccountGeneral/AccountGeneral';
import AccountPhoto from './AccountPhoto/AccountPhoto';
import AccountSign from './AccountSign/AccountSign';
import AccountProjects from './AccountProjects/AccountProjects';

const Account = () => {
  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    const savedActiveTab = sessionStorage.getItem('activeTab');
    if (savedActiveTab) {
      setActiveTab(savedActiveTab);
    }
  }, []);

  const handleTabToggle = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem('activeTab', tab);
  };

  return (
    <Container className="settings-account">
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className="p-0">
              <div className="collapse-list d-flex justify-content-md-start justify-content-center">
                <div className={activeTab === 'general' ? 'active' : ''} onClick={() => handleTabToggle('general')}>
                  General
                </div>
                <div className={activeTab === 'photo' ? 'active' : ''} onClick={() => handleTabToggle('photo')}>
                  Photo
                </div>
                <div className={activeTab === 'sign' ? 'active' : ''} onClick={() => handleTabToggle('sign')}>
                  Sign
                </div>
                <div className={activeTab === 'projects' ? 'active' : ''} onClick={() => handleTabToggle('projects')}>
                  Projects
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-12">
          {activeTab === 'general' && <AccountGeneral />}
          {activeTab === 'photo' && <AccountPhoto />}
          {activeTab === 'sign' && <AccountSign />}
          {activeTab === 'projects' && <AccountProjects />}
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
