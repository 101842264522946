import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import DataReactTable from '../../../../Tables/DataTable/components/DataReactTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialReportIncomeAction } from '../../../../../redux/actions/financialReportIncomesActions';
import {columns} from './DataReactTableColumns';
import { deleteFinancialReportIncome } from '../../../../../utils/api';
import ConfirmModal from '../../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import _ from "lodash";
import { parse, stringify } from 'query-string';


const FinancialReportIncomes = () => {
  const {financialReportIncome, errors, totalRecords} = useSelector(state => state.financialReportIncomes);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({start: 0, limit: 10, user_id: '', search_query: ''});
  let navigate = useNavigate();
  const authUser = useSelector(state => state.auth.user);


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`, {
      state: location.state ? location.state : null,
      replace: true,
    });
    dispatch(getFinancialReportIncomeAction(updatedQuery, id));
  }, [currentPage, pageLimit, refreshCounter, dispatch, query, id, location.pathname, navigate]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const handleDeleteIncome = () => {
    deleteFinancialReportIncome(id, deleteId, authUser.accessToken)
      .then(res => {
        if(res.data.success !== true) return;

        setRefreshCounter(refreshCounter + 1);
      })
  }

  const getSearchValue = _.debounce((value) => {
    setQuery({...query, search_query: value});
  }, 600)

  const prevNavigationQuery = location.state?.prevQuery ? [...location.state.prevQuery] : [];
  prevNavigationQuery.push({
      query: location.search,
      path: location.pathname,
  });

  return (
    <Container className="financialReportIncome">
      <Row>
        <Col sm={12} className="justify-content-end d-flex">
          <button onClick={() => {
            navigate(`/financials/reports/${id}/automatic-income`, {
              state: { prevQuery: prevNavigationQuery, }
            });
          }} className="btn btn-success">Add Income</button>
        </Col>
        <Col md={12}>
          <DataReactTable
            tableName={"Incomes"}
            tableClass = {"financialReportIncomes"}
            sortTable={false}
            search={true}
            searchValue={getSearchValue}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            emptyStateMessage={t('empty_states.incomes')}
            reactTableData={{tableHeaderData: columns, tableRowsData: financialReportIncome?.map((c) => {
                return {...c,
                  id: `${c.id}`,
                  from_date: `${c.from_date}`,
                  to_date: `${c.to_date}`,
                  amount: `${c.amount}`,
                  actions:
                    <div className={"tableActions"}>
                      <button
                        onClick={() => {
                          setDeleteId(c.id)
                          setModal(true)}}
                        style={{border: 0, background: 'transparent'}}
                      ><DeleteIcon size={18} color="#b1c3c8" /></button>
                    </div>
                }
              })}}
          />
          {
            errors ? (<div>{errors}</div>) : null
          }
          {
            <ConfirmModal
              color="danger"
              btn="Default"
              message="Are you sure you want to delete it?"
              toggle={toggle}
              modal={modal}
              onNext={handleDeleteIncome}
            />
          }
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialReportIncomes;
