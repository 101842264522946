import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { putBankAccount } from '../../../utils/api';
import { connect } from 'react-redux';
import CustomModal from '../../../shared/components/customComponents/CustomModal/CustomModal';

class MyBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      failedUpdateBankAccountModal: false,
      messageError: '',
      successUpdateBankAccountModal: false
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleSave = () => {
    const contentText = this.state.editorState.getCurrentContent().getPlainText();

    const accountData = {
      first_name: this.props.user.userData.first_name,
      last_name: this.props.user.userData.last_name,
      email: this.props.user.userData.email,
      role: this.props.user.userData.role,
      bank_account_en: contentText
    }

    putBankAccount(accountData, this.props.user.userId, this.props.user.accessToken).then(res => {
      if(res.data.success) {
        this.setState(prevState => ({
          successUpdateBankAccountModal: prevState.successUpdateBankAccountModal = true
        }))
      } else {
        this.setState(prevState => ({
          messageError: prevState.messageError = res.data.errors[0].message,
          failedUpdateBankAccountModal: prevState.failedUpdateBankAccountModal = true
        }))
      }
    })

    this.setState({ editorState: EditorState.createEmpty() });
  };

  render() {
    const { editorState, failedUpdateBankAccountModal, messageError, successUpdateBankAccountModal } = this.state;

    return (
      <Container className="myBankAccount">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
                <div className="d-flex justify-content-end mt-3">
                  <Button className="btn btn-primary text-white" onClick={this.handleSave}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CustomModal
          successModal={failedUpdateBankAccountModal}
          toggleCloseModal={() => this.setState(prevState => ({
            failedUpdateBankAccountModal: prevState.failedUpdateBankAccountModal = false
          }))}
          textModal={messageError}
          color={"danger"}
        />
        <CustomModal
          successModal={successUpdateBankAccountModal}
          toggleCloseModal={() => this.setState(prevState => ({
              successUpdateBankAccountModal: prevState.successUpdateBankAccountModal = false
          }))}
          textModal={`Field bank_account_en was successfully updated!`}
          color={"success"}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(MyBankAccount);
