import React, {useEffect, useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    Card,
    CardBody,
    Col,
    Button,
    ButtonToolbar,
} from 'reactstrap';
import validate from './validate';
import renderSelectField from "../../../../../../shared/components/form/Select";
import {useDispatch, useSelector} from "react-redux";
import {getAllModulesFetchingAction} from "../../../../../../redux/actions/modulesActions";
import {useNavigate, useParams} from "react-router-dom";
import {postAddModule} from "../../../../../../utils/api";
import _ from "lodash";
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';


const FormAddModule = (props) => {
    let navigate = useNavigate();
    const {id} = useParams();
    const {handleSubmit} = props;
    const {modules, arrayDeletedModules, errors} = useSelector(state => state.modules);
    const authUser = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [errorAddModule, setErrorAddModule] = useState('');


    useEffect(() => {
        dispatch(getAllModulesFetchingAction());
    }, [dispatch]);

    const onSubmit = (val) => {
        postAddModule(id, val.select.id, authUser.accessToken, authUser.clientToken)
            .then(res => {
                if(res.data.success) return navigate(`/settings/core-system/saas/${id}`);

                setErrorAddModule(res.data.errors[0].message);
                setError(true);
            }
        )
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form__form-group">
                            <span className="form__form-group-label">Select Modules</span>
                            <div className="form__form-group-field">
                                <Field
                                    name="select"
                                    component={renderSelectField}
                                    type="text"
                                    options={_.differenceBy(modules, arrayDeletedModules, 'id')?.map((item) => {
                                        return {
                                            id: item.id,
                                            value: item.name,
                                            label: item.name
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar">
                            <Button color="primary" type="submit">
                                Add
                            </Button>
                        </ButtonToolbar>
                    </form>
                </CardBody>
                {
                    errors ? (<div>{errors}</div>) : null
                }
            </Card>

            <CustomModal
              successModal={error}
              toggleCloseModal={() => setError(!error)}
              textModal={errorAddModule}
              color={"danger"}
            />
        </Col>
    );
};


export default reduxForm({
    form: 'form_add_module', // a unique identifier for this form
    validate,
})(FormAddModule);
